import KaspperLogo from '../../images/k_azul.png'
import { Grid, Paper, TextField, Button, CircularProgress } from "@mui/material"
import { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom/'
import Swal from 'sweetalert2'

const Login = () => {

    const navigate = useNavigate()
    const [user, setUser] = useState('')
    const [password, setPassword] = useState('')
    const [loading, setLoading] = useState(false)

    const handleLogin = useCallback(() => {
        setLoading(true)
        if (!user || !password) {
            Swal.fire({
                title: 'Ops!',
                text: 'Por favor informe o usuário e senha corretamente!',
                icon: 'error',
                confirmButtonColor: '#1976d2'
            })
            setLoading(false)
            return
        }

        setTimeout(() => {
            localStorage.setItem('user', user)
            navigate('/meu-atendimento')
            setLoading(false)
        }, 2000)

    }, [navigate, user, password]);

    return (
        <Grid container justifyContent="center" alignItems="center" style={{ width: '100%', height: '100%' }}>
            <Grid item xs={10} sm={6} md={4} xl={2}>
                <Paper elevation={3} style={{ padding: '20px' }}>
                    <img alt="Logo Kaspper" src={KaspperLogo} style={{ width: '200px', height: '100px', objectFit: 'contain' }} />
                    <TextField
                        value={user}
                        onChange={(e) => setUser(e.target.value)}
                        label="Usuário"
                        fullWidth
                        margin="normal"
                        variant="outlined"
                    />
                    <TextField
                        label="Senha"
                        fullWidth
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        margin="normal"
                        variant="outlined"
                        type="password"
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={handleLogin}
                    >
                        {loading ? (
                            <CircularProgress />
                        ) : 'Entrar'}
                    </Button>
                </Paper>
            </Grid>
        </Grid>
    )
}

export default Login