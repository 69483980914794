import { Grid, Typography, Paper, Button, CircularProgress } from "@mui/material"
import { DataGrid } from '@mui/x-data-grid'
import { useCallback, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import ChatIcon from '@mui/icons-material/Chat'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { capture, getAllNumbers } from "../../services/attendance"
import Header from "../../components/header"
import Footer from "../../components/footer"


const AtendimentosDisponiveis = () => {

    const navigate = useNavigate()

    const [rows, setRows] = useState([])
    const [loading, setLoading] = useState(false)
    const [loadingInit, setLoadingInit] = useState(false)

    const onCapture = useCallback(async (value) => {
        setLoadingInit(true)
        const result = await capture(rows.find(f => f.id === value).number, localStorage.getItem('user'))


        setTimeout(() => {
            navigate('/chat', {
                state: {
                    id: result.id
                }
            })
            setLoadingInit(false)
        }, 2000)

    }, [navigate, rows])

    const CustomButton = useCallback((props) => {
        const { value } = props

        return (
            <Button
                variant="contained"
                color="primary"
                disabled={loadingInit}
                fullWidth
                onClick={() => onCapture(value)}
            >
                {loadingInit ? (
                    <CircularProgress />
                ) : (
                    <>
                        <ChatIcon />
                        Iniciar Atendimento
                    </>
                )}
            </Button>
        )
    }, [loadingInit, onCapture]);

    const columns = [
        { field: 'number', headerName: 'Telefone', width: 200 },
        { field: 'id', headerName: 'Ação', width: 250, renderCell: CustomButton }
    ];


    useEffect(() => {
        const get = async () => {
            setLoading(true)
            const result = await getAllNumbers()
            setRows(result)

            setTimeout(() => {
                setLoading(false)
            }, 2000)
        }

        get()
    }, [])

    return (
        <Grid container justifyContent="center" alignItems="center" style={{ width: '100%', height: '100%' }}>
            <Grid item xs={10} sm={6} md={6} xl={4}>
                <Header />
                <Paper elevation={3} style={{ padding: '20px' }}>
                    <Grid container spacing={0.5} justifyContent="space-between">
                        <Grid item xs={12} md={8} xl={6}>
                            <Typography variant="h5" component="h2" style={{ fontSize: '18px', fontWeight: 'bold'}}>
                                Atendimentos Disponíveis
                            </Typography>
                        </Grid>
                        <Grid item xs={2} md={2} xl={1}>
                            <Button
                                variant="text"
                                color="primary"
                                fullWidth
                                onClick={() => navigate('/meu-atendimento')}
                            >
                                <ArrowBackIcon />
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid container mt={4}>
                        {loading ? (
                            <Grid item xs={12} style={{ display: 'flex' }} alignItems="center" justifyContent="center">
                                <CircularProgress />
                            </Grid>
                        ) : (
                            <Grid item xs={12}>
                                <DataGrid
                                    onRowClick={(result) => console.log(result)}
                                    rows={rows}
                                    columns={columns}
                                    initialState={{
                                        pagination: {
                                            paginationModel: { page: 0, pageSize: 5 },
                                        },
                                    }}
                                    pageSizeOptions={[5, 10]}
                                    checkboxSelection
                                />
                            </Grid>
                        )}

                    </Grid>
                </Paper>
                <Footer />
            </Grid >
        </Grid >
    )
}

export default AtendimentosDisponiveis