export const styles = {
    userMessage: {
      backgroundColor: '#1976d2',
      color: '#000',
      padding: '10px',
      borderRadius: '10px',
      marginBottom: '10px',
      alignSelf: 'flex-end',
      maxWidth: '50%',
    },
    otherMessage: {
      backgroundColor: '#ffffff',
      color: '#000',
      padding: '10px',
      borderRadius: '10px',
      marginBottom: '10px',
      alignSelf: 'flex-start',
      maxWidth: '50%',
    },
  };