import api from './index'

export const getMessagesByAttendance = async (id) => {
    const result = await api.get(`/messages/${id}`)
    return result.data
}

export const sendMessage = async (message, attendanceId, templateName, scheduleFor) => {
    const result = await api.post(`/messages`, {
        message,
        attendanceId,
        templateName,
        scheduleFor,
        sender: 'user'
    })

    return result.data
}