import { Grid } from "@mui/material"
import KaspperLogo from '../../images/k_azul.png'

const Header = () => {

    return (
        <Grid container>
            <Grid item xs={12} textAlign="center">
                <img alt="Logo Kaspper" src={KaspperLogo} style={{ width: '200px', height: '100px', objectFit: 'contain' }} />
            </Grid>
        </Grid>
    )
}

export default Header