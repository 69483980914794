import { Button, Grid } from "@mui/material"
import { useNavigate } from "react-router-dom"

const Footer = () => {

    const navigate = useNavigate()

    return (
        <Grid container justifyContent="flex-end">
            <Grid item xs={2} mt={2}>
                <Button
                    variant="contained"
                    color="error"
                    fullWidth
                    onClick={() => navigate('/')}
                >
                    Sair
                </Button>
            </Grid>
        </Grid>
    )
}

export default Footer