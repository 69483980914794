import { Grid, Typography, Modal, Box, Select, MenuItem, Button } from "@mui/material"
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import { useCallback, useState } from "react"
import { templates } from "../../utils/templates"

const ModalAtendimento = ({ open, handleCloseModal, withoutHour, onSend }) => {

    const [date, setDate] = useState(null)
    const [template, setTemplate] = useState(null)
    const [loading, setLoading] = useState(null)

    const onClose = useCallback(() => {
        handleCloseModal()
        setDate('')
        setTemplate(null)
    }, [handleCloseModal])

    const handleChangeTemplate = useCallback((e) => {
        const { target: { value } } = e
        setTemplate(value)
    }, [])

    const send = useCallback(() => {
        setLoading(true)
        onSend(templates.find(f => f.value === template), date)

        setTimeout(() => {
            handleCloseModal()
            setLoading(false)
        }, 2000)
    }, [onSend, template, date, handleCloseModal])

    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 400,
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 4,
            }}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    {withoutHour ? 'Template de mensagem' : 'Agendamento de Mensagem'} 
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    {withoutHour ? 'Selecione um template para enviar' : 'Selecione uma data e horário para enviar um template.'}
                </Typography>
                <Grid container spacing={0.5}>
                    {!withoutHour && (
                        <Grid item xs={12} mt={2}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer components={['DateTimePicker']}>
                                    <DateTimePicker value={date} label="Selecione uma data e hora" onChange={(value) => setDate(value)} />
                                </DemoContainer>
                            </LocalizationProvider>
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <Select
                            label="Selecione um template"
                            fullWidth
                            value={template}
                            onChange={handleChangeTemplate}
                        >
                            {templates.map((item) => (
                                <MenuItem
                                    key={item.name}
                                    value={item.value}
                                >
                                    {item.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </Grid>
                    <Grid item xs={12}>
                        {template && (
                            <img style={{ width: '300px', height: '300px' }} alt={templates.find(f => f.value === template).name} src={templates.find(f => f.value === template).img} />
                        )}
                    </Grid>
                    <Grid>
                        <Button
                            style={{ marginTop: '20px' }}
                            variant="contained"
                            color="primary"
                            fullWidth
                            onClick={send}
                            disabled={loading}
                        >
                            Enviar Mensagem
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Modal>
    )
}

export default ModalAtendimento