import { Grid, Typography, Paper, Button, CircularProgress } from "@mui/material"
import { DataGrid } from '@mui/x-data-grid'
import { useCallback, useEffect, useState } from "react"
import ChatIcon from '@mui/icons-material/Chat'
import { useNavigate } from 'react-router-dom/'
import ModalAtendimento from "../../components/modal-atendimento"
import { getByAttendant } from "../../services/attendance"
import Header from "../../components/header"
import Footer from "../../components/footer"
import Swal from "sweetalert2"
import { sendMessage } from "../../services/message"

const MeuAtendimento = () => {

    const navigate = useNavigate()

    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const [rows, setRows] = useState([])
    const [selectedRows, setSelectedRows] = useState([])

    const CustomButton = useCallback((props) => {
        const { value } = props

        return (
            <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={() => {
                    navigate('/chat', {
                        state: {
                            id: value
                        }
                    })
                }}
            >
                <ChatIcon />
                Conversar
            </Button>
        )
    }, [navigate])

    const handleSendSchedule = useCallback((template, date) => {
        setLoading(true)
        selectedRows.forEach(i => {
            const month = `${date.$M}`.padStart(2, '0')
            const day = `${date.$D}`
            const year = `${date.$y}`
            const hour = `${date.$H}`.padStart(2, '0')
            const minute = `${date.$m}`.padStart(2, '0')

            sendMessage("Mensagem Inicial", i, template.value, `${year}-${month}-${day}T${hour}:${minute}`)
        })

        setTimeout(() => {
            Swal.fire({
                title: 'Parabéns',
                text: 'Sua mensagem foi agendada com sucesso!',
                icon: 'success',
                confirmButtonColor: '#1976d2'
            })
        }, 2000)
        setLoading(false)
    }, [selectedRows])

    const columns = [
        { field: 'phoneNumber', headerName: 'Telefone', width: 200 },
        { field: 'id', headerName: 'Ação', width: 250, renderCell: CustomButton }
    ]


    useEffect(() => {
        const get = async () => {
            setLoading(true)
            const result = await getByAttendant(localStorage.getItem('user'))
            setRows(result)

            setTimeout(() => {
                setLoading(false)
            }, 2000)
        }

        get()
    }, [])


    return (
        <Grid container justifyContent="center" alignItems="center" style={{ width: '100%', height: '100%' }}>
            <ModalAtendimento open={open} handleCloseModal={() => setOpen(false)} onSend={handleSendSchedule} />
            <Grid item xs={10} sm={6} md={6} xl={4}>
                <Header />
                <Paper elevation={3} style={{ padding: '20px' }}>
                    <Grid container spacing={0.5}>
                        <Grid item xs={12} xl={4}>
                            <Typography variant="h5" component="h2" style={{ fontSize: '18px', fontWeight: 'bold'}}>
                                Meus atendimentos
                            </Typography>
                        </Grid>
                        <Grid item xs={12} xl={4}>
                            <Button
                                variant="contained"
                                color="primary"
                                fullWidth
                                onClick={() => navigate('/atendimentos-disponiveis')}
                            >
                                Novo Atendimento
                            </Button>
                        </Grid>
                        <Grid item xs={12} xl={4}>
                            <Button
                                variant="contained"
                                color="primary"
                                fullWidth
                                disabled={rows.length === 0 || loading || selectedRows.length === 0}
                                onClick={() => setOpen(true)}
                            >
                                Agendar Mensagem
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid container mt={4}>
                        <Grid item xs={12}>
                            {loading ? (
                                <Grid item xs={12} style={{ display: 'flex' }} alignItems="center" justifyContent="center">
                                    <CircularProgress />
                                </Grid>
                            ) : (
                                <DataGrid
                                    onRowSelectionModelChange={(result) => setSelectedRows(result)}
                                    rows={rows}
                                    columns={columns}
                                    initialState={{
                                        pagination: {
                                            paginationModel: { page: 0, pageSize: 5 },
                                        },
                                    }}
                                    pageSizeOptions={[5, 10]}
                                    checkboxSelection
                                />
                            )}
                        </Grid>
                    </Grid>
                </Paper>
                <Footer />
            </Grid>
        </Grid>
    )
}

export default MeuAtendimento