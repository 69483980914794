import api from './index';

export const getByAttendant = async (attendant) => {
    const result = await api.get(`/attendances/${attendant}`);
    return result.data
}

export const getAllNumbers = async () => {
    const result = await api.get('/attendances');
    return result.data
}

export const capture = async (number, attendant) => {
    const result = await api.post('/attendances', {
        clientAttendancePhoneNumber: number,
        attendant
    });

    return result.data
}