import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom"
import './reset.css';
import 'sweetalert2/src/sweetalert2.scss'
import { Login, MeuAtendimento, Chat, AtendimentosDisponiveis } from "./pages";

const App = () => {

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Login />
    },
    {
      path: '/meu-atendimento',
      element: <MeuAtendimento />
    },
    {
      path: '/atendimentos-disponiveis',
      element: <AtendimentosDisponiveis />
    },
    {
      path: '/chat',
      element: <Chat />
    }
  ]);

  return (
    <div className="App" style={{ width: '100vw', height: '100vh', backgroundColor: 'rgba(10, 189, 227, 0.1)' }} >
      <RouterProvider router={router} />
    </div>
  )
}

export default App
