import { Grid, Button, Typography, Container, TextField, IconButton, Paper } from '@mui/material'
import SendIcon from '@mui/icons-material/Send'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { useNavigate, useLocation } from 'react-router-dom'
import { useCallback, useEffect, useState } from "react"
import { getMessagesByAttendance, sendMessage } from "../../services/message"
import { styles } from './styles'
import ModalAtendimento from '../../components/modal-atendimento'
import Header from '../../components/header'
import { templates } from '../../utils/templates'
import Footer from '../../components/footer'

const Chat = () => {

    const navigate = useNavigate()
    const location = useLocation()
    const [messages, setMessages] = useState([])
    const [message, setMessage] = useState('')
    const [open, setOpen] = useState(false)

    const handleSendTemplate = useCallback(async (template, _) => {
        const result = await sendMessage("Mensagem Inicial", location.state.id, template.value, null)
        setMessages((old) => {
            const cp = [...old]
            cp.push(result)
            return cp
        })
    }, [location])

    const handleSendMessage = useCallback(async () => {
        const result = await sendMessage(message, location.state.id, null, null)
        setMessages((old) => {
            const cp = [...old]
            cp.push(result)
            return cp
        })
        setMessage('')
    }, [message, location])

    useEffect(() => {
        const get = async () => {
            const result = await getMessagesByAttendance(location.state.id)
            setMessages(result)
        }

        setInterval(() => {
            get()
        }, 4000)

        get()
    }, [location])


    return (
        <Grid container justifyContent="center" alignItems="center" style={{ width: '100%', height: '100%' }}>
            <Grid item xs={10} sm={6} md={8} xl={4}>
                <Header />
                <Paper elevation={3} style={{ padding: '20px' }}>
                    <Grid container spacing={0.5} justifyContent="space-between">
                        <Grid item xs={12} md={4} xl={4}>
                            <Typography variant="h5" component="h2" style={{ fontSize: '18px', fontWeight: 'bold'}}>
                                Chat
                            </Typography>
                        </Grid>
                        <Grid item xs={2} md={1} xl={1}>
                            <Button
                                variant="text"
                                color="primary"
                                fullWidth
                                onClick={() => navigate('/meu-atendimento')}
                            >
                                <ArrowBackIcon />
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid container mt={4}>
                        <ModalAtendimento withoutHour open={open} handleCloseModal={() => setOpen(false)} onSend={handleSendTemplate} />
                        <Grid item xs={12}>
                            <Container style={{ marginTop: '20px' }}>
                                {messages.filter(f => f.sender === 'webhook' || f.sender === 'user').length === 0 ? (
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <Paper style={{ padding: '20px' }}><Typography variant='h6' component="h2" style={{ padding: 0, margin: 0, textAlign: 'center' }}>A conversa ainda não iniciou, por favor envie um template para iniciar a conversa.</Typography></Paper>
                                    </div>
                                ) : (
                                    <div style={{ display: 'flex', flexDirection: 'column', marginRight: '40px', maxHeight: '410px', minHeight: '410px', overflow: 'auto' }}>
                                        {messages.map((message, index) => (
                                            <Paper
                                                key={index}
                                                style={message.sender === 'user' ? styles.userMessage : styles.otherMessage}
                                            >
                                                {message.templateName ? (
                                                    <img style={{ width: '300px', height: '300px' }} alt={templates.find(f => f.value === message.templateName).name} src={templates.find(f => f.value === message.templateName).img} />
                                                ) : (
                                                    <p style={{ margin: 0, padding: 0, wordWrap: 'break-word' }}>{message.message}</p>
                                                )}
                                            </Paper>
                                        ))}
                                    </div>
                                )}
                                <div style={{ display: 'flex', marginTop: '20px' }}>
                                    {messages.filter(f => f.sender === 'webhook').length > 0 ? (
                                        <>
                                            <TextField
                                                label="Digite uma mensagem"
                                                variant="outlined"
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        handleSendMessage()
                                                    }
                                                }}
                                                fullWidth
                                                value={message}
                                                onChange={(e) => setMessage(e.target.value)}
                                            />
                                            <IconButton disabled={!message} onClick={handleSendMessage} color="primary">
                                                <SendIcon />
                                            </IconButton>
                                        </>
                                    ) : (
                                        <>
                                            {messages.length === 0 && (
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    fullWidth
                                                    onClick={() => setOpen(true)}
                                                >
                                                    Enviar Template
                                                </Button>
                                            )}
                                        </>
                                    )}
                                </div>
                            </Container>
                        </Grid>
                    </Grid>
                </Paper>
                <Footer />
            </Grid >
        </Grid >
    )
}

export default Chat